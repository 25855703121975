<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center">Prohibited or Restricted Items</h1>
        <div class="lead">
          To avoid wasted time and stress, please review the following list of prohibited items to
          ensure that your next shipment doesn't end up delayed. If you are unsure about whether an
          item is prohibited, please contact our Customer Service team PRIOR to purchasing.
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-01.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted">
          Combustible and flammable items including paints, oils, lighters, perfume, nail polishes,
          solvents, corrosive,lithium ion batteries (either contained in, packaged with, or
          individually packaged) or any other Danger Good described in the DOT 49cfr regulations and
          or the IATA Regulations, etc.
        </small>
      </div>
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-02.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>
        <small class="text-muted">
          Pressurized materials such as hair spray, shaving cream, or spray cans of any type and
          hazardous materials such as fuels, chemicals, or explosives.
        </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-03.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted">
          Firearms, weaponry, and their components and parts, including military, police and
          tactical equipment of any kind, guns, gun replicas, gun accessories, gun components
          (magazines, clips, and rail systems), ammunition, knives with blades over 6", swords,
          compound bows, crossbows, discharge weapons such as shock batons or stun guns, tooling for
          guns or gun parts, paintball guns and accessories, optical scopes, laser sights, night
          sights, night vision goggles, handcuffs and other restraints, and surveillance equipment .
          Basically, if it can be used as a weapon, we won't ship it.
        </small>
      </div>
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-04.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>
        <small class="text-muted">
          Agricultural products such as plants, seeds, fruit, and nuts.
        </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-05.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted"> Perishable foods of any type are prohibited.</small>
      </div>
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-06.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>
        <small class="text-muted">
          Animals and products made with animal fur are prohibited in most countries.
        </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-07.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted">
          Alcohol of any quantity, tobacco and nicotine products of any kind, including e-cigarettes
          and their components..
        </small>
      </div>
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-08.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>
        <small class="text-muted">
          Cash, currency, money orders, credit and debit cards (including prepaid cards), bullion
          and precious metals, cashiers' checks, bank drafts, bearer bonds, collectible stamps and
          coins, lottery tickets, or gambling devices and paraphernalia.
        </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-09.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted">
          Jewelry and precious stones are restricted to some countries and only insurable up to a
          maximum of $500. Any shipments of jewelry in excess of the $500 carriage limit will be
          uninsured and the customer assumes all risk of loss above such limit.
        </small>
      </div>
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-10.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>
        <small class="text-muted"> Prescription medications are prohibited. </small>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6 text-justify">
        <div class="float-none float-md-start text-center">
          <img src="/img/prohibit/prohit-img-11.jpg" class="img-fluid me-2" alt="prohibit items" />
        </div>

        <small class="text-muted"> Pornography is prohibited in most countries. </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prohibit',
  created() {
    document.title = 'Trepr - Prohibited or Restricted Items | Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        "To avoid wasted time and stress, please review the list of prohibited items to ensure that your next shipment doesn't end up delayed. If you are unsure about whether an item is prohibited, please contact our Customer Service team PRIOR to purchasing."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
